import { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { EthereumClient } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";

import App from "./App";
import store from "./store";
import { WagmiConfig } from "wagmi";
import { chains, projectId, wagmiConfig } from "./configs/wagmiConfig";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { BlockNumberProvider } from "./lib/hooks/useBlockNumber";
import { MulticallUpdater } from "./lib/state/multicall";
const Updaters = () => {
  return <MulticallUpdater />;
};

const ethereumClient = new EthereumClient(wagmiConfig, chains);
export default class Root extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Provider store={store}>
        <WagmiConfig config={wagmiConfig}>
          <RainbowKitProvider chains={chains}>
            <BlockNumberProvider>
              <Updaters />
              <BrowserRouter basename={"/#"}>
                <App />
              </BrowserRouter>
            </BlockNumberProvider>
          </RainbowKitProvider>
        </WagmiConfig>
        {/* <Web3Modal projectId={projectId} ethereumClient={ethereumClient} /> */}
      </Provider>
    );
  }
}
